import React, { FC } from 'react';
import { authorizeAgency } from 'src/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { errorAction, successAction } from 'src/utils/notifications';
import State from '@wfp-root-app/store/state';
import { ManagerPermission } from 'src/permission-profiles/permission';

import { AgencyResponse } from './types';

export type AgenciesListProps = {
    agencies: AgencyResponse[];
    onAuthorize: () => void;
};

export const AgenciesList: FC<AgenciesListProps> = ({ agencies, onAuthorize }) => {
    const dispatch = useDispatch();
    const auth = useSelector((state: State) => state.auth);

    const handleAuthorize = async (agencyId: string) => {
        try {
            await authorizeAgency(agencyId);
            dispatch(successAction('Agency authorized successfully'));
            onAuthorize();
        } catch (error) {
            dispatch(errorAction(error));
        }
    };
    return (
        <main>
            <h2>Agencies</h2>
            <table className="wfp-table mt4 table-hover">
                <thead>
                    <tr>
                        <th>Agency name</th>
                        <th>Abbreviation</th>
                        <th>Authorized at</th>
                    </tr>
                </thead>
                <tbody>
                    {agencies.map((agency) => (
                        <tr key={agency.id}>
                            <td>{agency.name}</td>
                            <td>{agency.abbreviation}</td>
                            <td>
                                {agency.authorizedAt ? (
                                    <span>{new Date(agency.authorizedAt).toLocaleDateString()}</span>
                                ) : (
                                    auth.manager.profile.permissions.includes(ManagerPermission.agencyPost) && (
                                        <a onClick={() => handleAuthorize(agency.id)}>Post</a>
                                    )
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </main>
    );
};
